import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-auto',
    templateUrl: './auto.component.html'
})
export class AutoComponent implements OnInit{
    constructor(private router:Router){}

    ngOnInit() {
        setTimeout(() => {
            var browserIsIE = navigator.userAgent.toUpperCase().indexOf("TRIDENT/") != -1 || navigator.userAgent.toUpperCase().indexOf("MSIE") != -1;
            if(browserIsIE){
                $("#browserMessageModal").addClass("show");
                $("#modalBackdrop").addClass("show");
            }
        }, 3000);
    };
    hideBrowserModal = function () {
        $("#browserMessageModal").removeClass("show");
        $("#modalBackdrop").removeClass("show");
    };
    signUpNow = function (planName) {
      var signUpURL = localStorage.getItem("signUpURL") + "/signup/geotax?plan=" + planName;
      window.location.href = signUpURL;
    };
}