import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit{
    public showpage:boolean = false;
    public activeTab: string = 'monthly';
    constructor(private router:Router, private activatedRoute: ActivatedRoute, private userService: UserService){}

    ngOnInit() {
        window.scroll(0,0);
        this.userService.getUserDeatails().subscribe(
            success => {
                if(this.activatedRoute.snapshot.url.length === 0){
                    window.location.href = '/geotax';
                }
            },
            error => {
                this.showpage = true;
            }
        );
        this.userService.getResources().subscribe(
            success => {
                //localStorage.setItem("mapFullURL", 'https://api.precisely.com/maps/v1/tile/osm/{z}/{x}/{y}.png?api_key=' + success['apiKey'] + '&theme=bronze');
                localStorage.setItem("recurlyConfig", success['recurlyConfig']);
                localStorage.setItem("signUpURL", success['signUpURL']);
            }
        );
        setTimeout(() => {
            var browserIsIE = navigator.userAgent.toUpperCase().indexOf("TRIDENT/") != -1 || navigator.userAgent.toUpperCase().indexOf("MSIE") != -1;
            if(browserIsIE){
                $("#browserMessageModal").addClass("show");
                $("#modalBackdrop").addClass("show");
            }
        }, 3000);
    };
    hideBrowserModal = function () {
        $("#browserMessageModal").removeClass("show");
        $("#modalBackdrop").removeClass("show");
    };
    signUpNow = function (planName) {
      var signUpURL = localStorage.getItem("signUpURL") + "/signup/geotax?plan=" + planName;
      window.location.href = signUpURL;
    };
    showSignupModal = function () {
        $("#signupModal").addClass("show");
        $("#modalBackdrop").addClass("show");
    };
    hideSignupModal = function () {
        $("#signupModal").removeClass("show");
        $("#modalBackdrop").removeClass("show");
    };
}